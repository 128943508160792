











































import {SupportTeamInterface} from '@/shared/interfaces/store/homepage/support-team.interface';
import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomeSupportTeam extends Vue {

  get supportTeam(): SupportTeamInterface {
    return HomepageStore.supportTeam;
  }

}
